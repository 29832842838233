import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { ReactElement } from 'react';
import { ComponentIds } from '../../utils/component-ids';

interface ConfirmDialogProps {
  open: boolean;
  icon?: ReactElement;
  title?: string;
  message?: string;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
  children?: any;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  return (
    <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="confirm-dialog">
      <DialogTitle className="text-white">
        {!!props.icon && (
          <span className="mr-3" style={{ position: 'relative', top: 5 }}>
            {props.icon}
          </span>
        )}
        {props.title || 'Confirm'}
      </DialogTitle>
      <DialogContent>
        {props.message && <DialogContentText>{props.message}</DialogContentText>}
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onConfirm} data-test={ComponentIds.ConfirmDialogConfirmButton}>
          {props.confirmBtnLabel || 'Confirm'}
        </Button>
        <Button onClick={props.onCancel} color="secondary" data-test={ComponentIds.ConfirmDialogCancelButton}>
          {props.cancelBtnLabel || 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
