import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'rdx/types';
import { IUserRoles, IUserSummary, RelatedUserTypes } from '../../interfaces';
import { QuoteSelectors, SessionSelectors } from '../session';
import { StatementSelectors } from './statement-tab/statement-tab.selectors';

const makeRelatedUserSelectors = (
  role: keyof IUserRoles,
  selectSavedUser: (state: RootState) => IUserSummary | undefined
) => {
  const users = createSelector(SessionSelectors.branchUsers, selectSavedUser, (branchUsers, savedUser) => {
    const users: IUserSummary[] = [];

    // If current saved user is not in the list of users, add them to the list.
    if (savedUser && !branchUsers.find(({ id }) => id === savedUser.id)) {
      users.push({ id: savedUser.id, details: savedUser.details });
    }

    for (const { id, details, security } of branchUsers) {
      const hasRole = !!security?.roles?.[role];
      if (hasRole || id === savedUser?.id) {
        users.push({ id, details });
      }
    }
    return users;
  });

  const current = createSelector(users, selectSavedUser, (users, savedUser) => {
    if (!savedUser?.id) {
      return null;
    }
    return users.find(({ id }) => id === savedUser.id) ?? null;
  });

  return { users, current };
};

export const QuoteScreenSelectors = {
  customerTabTouched: (state: RootState) =>
    state.quoteScreen.customerTab.account.status.touched ||
    state.quoteScreen.customerTab.contact.status.touched ||
    state.quoteScreen.customerTab.jobsite.status.touched ||
    state.quoteScreen.customerTab.marketSegment.status.touched ||
    state.quoteScreen.customerTab.billRequirements.status.touched,

  detailsTabTouched: (state: RootState) => state.quoteScreen.detailsTab.status.touched,

  feesTouched: (state: RootState) => state.quoteScreen.productsTab.feesStatus.touched,

  rentalTouched: (state: RootState) => state.quoteScreen.productsTab.products.rental.status.touched,

  salesTouched: (state: RootState) => state.quoteScreen.productsTab.products.sales.status.touched,

  servicesTouched: (state: RootState) => state.quoteScreen.productsTab.products.services.status.touched,

  optionalRentalTouched: (state: RootState) => state.quoteScreen.productsTab.optional.rental.status.touched,

  optionalSalesTouched: (state: RootState) => state.quoteScreen.productsTab.optional.sales.status.touched,

  optionalServicesTouched: (state: RootState) => state.quoteScreen.productsTab.optional.services.status.touched,

  optionalNotesTouched: (state: RootState) => state.quoteScreen.productsTab.optional.notes.status.touched,

  customerTabValid: (state: RootState) =>
    state.quoteScreen.customerTab.account.status.valid &&
    state.quoteScreen.customerTab.contact.status.valid &&
    state.quoteScreen.customerTab.billRequirements.status.valid,

  detailsTabValid: (state: RootState) => state.quoteScreen.detailsTab.status.valid,

  productsTabValid: (state: RootState) =>
    state.quoteScreen.productsTab.products.rental.status.valid &&
    state.quoteScreen.productsTab.products.services.status.valid &&
    state.quoteScreen.productsTab.products.services.status.valid,

  optionalTabValid: (state: RootState) =>
    state.quoteScreen.productsTab.optional.rental.status.valid &&
    state.quoteScreen.productsTab.optional.services.status.valid &&
    state.quoteScreen.productsTab.optional.services.status.valid,

  touched: (state: RootState) =>
    QuoteScreenSelectors.customerTabTouched(state) ||
    QuoteScreenSelectors.detailsTabTouched(state) ||
    QuoteScreenSelectors.feesTouched(state) ||
    QuoteScreenSelectors.rentalTouched(state) ||
    QuoteScreenSelectors.salesTouched(state) ||
    QuoteScreenSelectors.servicesTouched(state) ||
    QuoteScreenSelectors.optionalRentalTouched(state) ||
    QuoteScreenSelectors.optionalSalesTouched(state) ||
    QuoteScreenSelectors.optionalServicesTouched(state) ||
    QuoteScreenSelectors.optionalNotesTouched(state) ||
    StatementSelectors.touched(state),

  valid: (state: RootState) =>
    QuoteScreenSelectors.customerTabValid(state) &&
    QuoteScreenSelectors.detailsTabValid(state) &&
    QuoteScreenSelectors.productsTabValid(state) &&
    QuoteScreenSelectors.optionalTabValid(state),

  relatedUsers: {
    [RelatedUserTypes.Estimator]: makeRelatedUserSelectors('estimator', QuoteSelectors.estimator),
    [RelatedUserTypes.SupportSpecialist]: makeRelatedUserSelectors(
      'supportSpecialist',
      QuoteSelectors.supportSpecialist
    ),
  },
};
