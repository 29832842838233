import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ICellRendererParams } from 'ag-grid-community';
import { QuoteService } from 'api';
import { QuoteScreenSelectors, QuoteSelectors, sessionSlice } from 'rdx';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { AccountTypes } from '../../enums';
import { useUnmountedState } from '../../hooks/useUnmountedState';
import { IQuote } from '../../interfaces';
import { useAppDispatch } from '../../rdx/redux-hooks';
import { ComponentIds } from '../../utils/component-ids';
import { QuoteStatusAdapter } from '../../utils/QuoteStatusAdapter';
import {
  CloneMenuItemContent,
  DeleteMenuItemContent,
  MarkLostMenuItemContent,
  MarkWonMenuItemContent,
  PreviewMenuItemContent,
} from '../ActionMenuItemPartials/ActionMenuItemPartials';
import { QuoteActionsContext } from './QuoteActionsContext';

interface IQuoteActionsCellRendererProps {
  showPreview?: boolean;
  showClone?: boolean;
  showMarkAsWon?: boolean;
  showMarkAsLost?: boolean;

  /** @default false */
  showDelete?: boolean;
}

type Props = ICellRendererParams & IQuoteActionsCellRendererProps;

export const QuoteActionsCellRenderer: React.FC<Props> = (props: Props) => {
  const actionCallbacks = useContext(QuoteActionsContext);
  const quote: IQuote = props.data;
  const quoteId = quote.id;
  const isProspect = quote.header.main.account.accountTypeId === AccountTypes.PROSPECT;

  const quoteSaved = useSelector(QuoteSelectors.saved);
  const quoteTouched = useSelector(QuoteScreenSelectors.touched);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showPreview = props.showPreview ?? true;
  const showClone = props.showClone ?? true;

  const status = new QuoteStatusAdapter(quote);
  const showMarkAsWon = status.canBeMarkedWon && (props.showMarkAsWon ?? true);
  const showMarkAsLost = status.canBeMarkedLost && (props.showMarkAsLost ?? true);

  const unmountedState = useUnmountedState();
  const [permissionsChecked, setPermissionsChecked] = useState(false);
  const [cloneDisabled, setCloneDisabled] = useState(true);
  const [markWonDisabled, setMarkWonDisabled] = useState(true);
  const [markLostDisabled, setMarkLostDisabled] = useState(true);
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const showDelete = props.showDelete === true && status.canBeDeleted;

  const resetPermissions = () => {
    if (!unmountedState.unmounted) {
      setPermissionsChecked(false);
      setCloneDisabled(true);
      setMarkWonDisabled(true);
      setMarkLostDisabled(true);
      setDeleteDisabled(true);
    }
  };

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (!permissionsChecked && (showClone || showMarkAsWon || showMarkAsLost || showDelete)) {
      setPermissionsChecked(true);
      try {
        const data = await QuoteService.getInstance().getQuoteGroups(quoteId, []);
        if (!unmountedState.unmounted) {
          setCloneDisabled(!data.extensions?.permissions?.canBeCloned);
          setMarkWonDisabled(!data.extensions?.permissions?.canBeWon);
          setMarkLostDisabled(!data.extensions?.permissions?.canBeLost);
          setDeleteDisabled(!data.extensions?.permissions?.canBeDeleted);
        }
      } catch {
        if (!unmountedState.unmounted) {
          // Will check permissions again next time user opens the menu.
          setPermissionsChecked(false);
        }
      }
    }
  };

  const dispatch = useAppDispatch();

  const openPdf = () => {
    dispatch(
      sessionSlice.actions.openPdfViewer({
        quoteId,
        changeOrderId: null,
        quoteStatusData: quote,
        code: quote.header.main.code,
      })
    );
    handleClose();
  };

  const openCloneDialog = () => {
    dispatch(
      sessionSlice.actions.openCloneDialog({
        quoteId,
        migrated: quote.header.migration?.migrated === true,
        cloned: quote.header.clone.cloned,
      })
    );
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick} disabled={quoteTouched && quoteSaved} size="small">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { minWidth: 150 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/* PDF */}
        {showPreview && (
          <MenuItem onClick={openPdf} data-test={ComponentIds.QuotePreviewMenuItem}>
            <PreviewMenuItemContent />
          </MenuItem>
        )}

        {/* Clone */}
        {showClone && (
          <MenuItem onClick={openCloneDialog} disabled={cloneDisabled} data-test={ComponentIds.QuoteCloneMenuItem}>
            <CloneMenuItemContent />
          </MenuItem>
        )}

        {/* Mark As Won */}
        {showMarkAsWon && (
          <MenuItem
            disabled={markWonDisabled}
            onClick={() => {
              // Reset permissions on success because this operation may affect them.
              actionCallbacks.markWon(quoteId, isProspect, resetPermissions);
              handleClose();
            }}
            data-test={ComponentIds.QuoteMarkWonMenuItem}
          >
            <MarkWonMenuItemContent />
          </MenuItem>
        )}

        {/* Mark As Lost */}
        {showMarkAsLost && (
          <MenuItem
            disabled={markLostDisabled}
            onClick={() => {
              actionCallbacks.markLost(quoteId, resetPermissions);
              handleClose();
            }}
            data-test={ComponentIds.QuoteMarkLostMenuItem}
          >
            <MarkLostMenuItemContent />
          </MenuItem>
        )}

        {/* Delete */}
        {showDelete && (
          <MenuItem
            disabled={deleteDisabled}
            onClick={() => {
              actionCallbacks.deleteQuote(quote);
              handleClose();
            }}
            data-test={ComponentIds.QuoteDeleteMenuItem}
          >
            <DeleteMenuItemContent />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
