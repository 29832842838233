import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { isNil, keyBy } from 'lodash';
import { MarkRequired } from 'ts-essentials';
import { v4 } from 'uuid';
import { ServicesService } from '../../../api';
import { getGridValidationGroupNameByLocation } from '../../../containers/Quote/Tabs/Products/Tabs/grid-validation';
import { getOppositeProductsTab, QuoteInnerTabs, QuoteProductTabs, QuoteTabs, ServicesPhases } from '../../../enums';
import { IServicesGrid, IServicesGridRow, ServiceRowLocation } from '../../../interfaces';
import { i18nKeys } from '../../../internationalization/i18nKeys';
import { enqueueNotificationAction, QuoteSelectors, showNotification } from '../../session';
import { AppDispatch } from '../../store';
import { AppThunkApiConfig, RootState } from '../../types';
import { reviewTabSlice } from '../review-tab';
import { addServiceRowsAction, deleteItemAction } from './products-tab.action-creators';
import { findTargetIndexForRowShift } from './products-tab.helpers';
import { getProductSelectors, ProductSelectors } from './products-tab.selectors';
import { FetchServiceProfileResult } from './products-tab.types';
import { findServicesGrid, getServiceKey } from './services.helpers';

export type SetCopyingServicesPayload = { tab: QuoteProductTabs; copying: boolean };

export const setCopyingServicesAction = createAction<SetCopyingServicesPayload>(
  'quote/productsTab/setCopyingServicesAction'
);

export const updateServicesDriverAndTruckTotalsAction = createAction<{ tab: QuoteProductTabs }>(
  'quote/productsTab/updateServicesDriverAndTruckTotalsAction'
);

export const fetchServicesPhasesAction = createAsyncThunk(
  'quote/productsTab/fetchServicesPhasesAction',
  async (payload: { tab: QuoteTabs }, thunkAPI) => {
    try {
      const service = ServicesService.getInstance();
      let phases = await service.getPhases();
      phases = phases.sort((a, b) => (a.sort < b.sort ? -1 : 1));
      return {
        tab: payload.tab,
        phases,
      };
    } catch (e) {
      thunkAPI.dispatch(
        // TODO: Wording of this error message and add a 'Try again'
        // button that re-triggers the call
        enqueueNotificationAction({
          message: 'An error occurred, please refresh the page and try again.',
          options: { variant: 'error' },
        })
      );
      throw e;
    }
  }
);

export const fetchServiceProfileAction = createAsyncThunk<
  FetchServiceProfileResult,
  ServiceRowLocation & {
    phaseId: string;
    serviceId: string;
    code: string;
    setRates?: boolean;
  }
>('quote/productsTab/fetchServiceProfileAction', async (payload, thunkAPI) => {
  const { rowId, code, setRates, gridId, phaseId, tab, serviceId } = payload;
  const state = thunkAPI.getState() as RootState;

  let profile = state.quoteScreen.productsTab.profiles.services[getServiceKey(phaseId, code)];
  if (!profile) {
    const branchId = QuoteSelectors.branchId(state);
    const accountId = QuoteSelectors.accountId(state);
    if (!branchId || !accountId) {
      return thunkAPI.rejectWithValue('Both branchId and accountId are mandatory');
    }
    try {
      profile = await ServicesService.getInstance().getServiceProfile(phaseId, serviceId, branchId, accountId);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
  return { setRates: setRates ?? true, tab, gridId, rowId, code, profile };
});

export const fetchServiceProfiles = async (state: RootState, dispatch: AppDispatch, tab: QuoteProductTabs) => {
  const isDraftQuote = ProductSelectors.isDraftQuote(state);

  const promises = getProductSelectors(tab)
    .filteredServiceGrids(state)
    .flatMap((grid) =>
      grid.rows
        .filter((row): row is MarkRequired<IServicesGridRow, 'service'> =>
          Boolean(row.service && (isDraftQuote || !row.rateTierColor || !row.redlineLevelColor))
        )
        .map((row) =>
          dispatch(
            fetchServiceProfileAction({
              tab,
              gridId: grid.gridId,
              rowId: row.rowId,
              phaseId: grid.phaseId,
              serviceId: row.service.serviceId,
              code: row.service.code,
              setRates: false,
            })
          )
        )
    );

  const productsNotFound = await Promise.allSettled(promises.map((promise) => promise.unwrap())).then((outcomes) =>
    outcomes.some((outcome) => outcome.status === 'rejected' && outcome.reason?.statusCode === 404)
  );

  return { productsNotFound };
};

export const fetchServiceProfilesAction = createAsyncThunk<void, { tab: QuoteProductTabs }, AppThunkApiConfig>(
  'quote/productsTab/fetchServiceProfilesAction',
  async ({ tab }, { dispatch, getState }) => {
    await fetchServiceProfiles(getState(), dispatch, tab);
  }
);

type AddServiceRowsParam = {
  tab: QuoteProductTabs;
  gridId: string;
  phaseId: string;
  services: IServicesGridRow[];
  source: 'search' | 'copy';
};

export const addServicesAction = createAsyncThunk(
  'quote/productsTab/addServicesAction',
  async (payload: AddServiceRowsParam, thunkAPI) => {
    const { tab, gridId, phaseId, services, source } = payload;
    if (Array.isArray(services)) {
      // Create new service grid rows for target grid.
      const newServiceItems = services.map((sourceRow) => {
        const row: IServicesGridRow = {
          // Copy everything from source row because we want comments, Qty, etc.
          ...sourceRow,

          // Reset some props.
          tab,
          gridId,
          rowId: v4(),
          validations: {
            missingDriver: false,
            missingTruck: false,
          },
          status: {
            touched: true,
            fetched: false,
            fetching: false,
            error: false,
          },

          // All other props will get updated from service profile.
        };
        return row;
      });

      // Add new service rows to target grid.
      thunkAPI.dispatch(
        addServiceRowsAction({
          tab,
          gridId,
          rows: newServiceItems,
        })
      );

      // Get profiles for the new rows.
      newServiceItems.forEach((row: IServicesGridRow) => {
        if (row.code && row.serviceId) {
          thunkAPI.dispatch(
            fetchServiceProfileAction({
              tab,
              gridId,
              phaseId,
              rowId: row.rowId,
              serviceId: row.serviceId,
              code: row.code,
              setRates: source === 'search',
            })
          );
        }
      });
    }
  }
);

type CopyServicesParam = {
  move: boolean;
  sourceGridId: string;
  sourceRowId?: string;
  targetGrid: IServicesGrid;
};

export const copyServicesAction = createAsyncThunk(
  'quote/productsTab/copyServicesAction',
  async (param: CopyServicesParam, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const { move, sourceGridId, sourceRowId, targetGrid } = param;

    const branchId = QuoteSelectors.branchId(state);
    const sourceGrid = ProductSelectors.servicesGrid(state, sourceGridId);
    if (sourceGrid && branchId) {
      // Collect rows to copy from source grid.
      let sourceRows;
      if (sourceRowId) {
        const row = sourceGrid.rows.find(({ rowId }) => rowId === sourceRowId);
        if (row) {
          sourceRows = [row];
        }
      } else if (sourceGrid.selected.length > 0) {
        sourceRows = sourceGrid.rows.filter((r) => sourceGrid.selectedById[r.rowId]);
      }

      if (sourceRows && sourceRows.length > 0) {
        const originalRowCount = sourceRows.length;

        // If source and target phases are different, we need to check which services can be copied.
        // If phase IDs are the same, we can skip this time-consuming check.
        if (sourceGrid.phaseId !== targetGrid.phaseId) {
          thunkAPI.dispatch(
            setCopyingServicesAction({
              tab: sourceGrid.tab,
              copying: true,
            })
          );

          // Get list of services supported by target phase.
          try {
            const targetPhaseServices = await ServicesService.getInstance().getPhaseServices(
              targetGrid.phaseId,
              branchId
            );
            const targetPhaseServicesById = keyBy(targetPhaseServices, 'serviceId');
            sourceRows = sourceRows.filter(({ serviceId }) => serviceId && targetPhaseServicesById[serviceId]);
          } catch (e) {
            showNotification(thunkAPI.dispatch, 'error', i18nKeys.quote.services.errorGettingPhaseServices);
            return;
          } finally {
            thunkAPI.dispatch(
              setCopyingServicesAction({
                tab: sourceGrid.tab,
                copying: false,
              })
            );
          }
        }

        if (sourceRows.length === 0) {
          showNotification(
            thunkAPI.dispatch,
            'warning',
            i18nKeys.quote.services.selectedServicesNotSupportedByTargetPackage,
            {
              count: originalRowCount,
            }
          );
        } else {
          thunkAPI.dispatch(
            addServicesAction({
              tab: targetGrid.tab,
              gridId: targetGrid.gridId,
              phaseId: targetGrid.phaseId,
              services: sourceRows,
              source: 'copy',
            })
          );

          if (move) {
            // If move to another tab then removes the services from the current tab
            sourceRows.forEach((service) => {
              thunkAPI.dispatch(
                deleteItemAction({
                  tab: service.tab,
                  innerTab: QuoteInnerTabs.Services,
                  gridId: service.gridId,
                  rowId: service.rowId,
                })
              );
            });
          }

          thunkAPI.dispatch(updateServicesDriverAndTruckTotalsAction({ tab: targetGrid.tab }));

          showNotification(thunkAPI.dispatch, 'success', i18nKeys.quote.services.servicesCopied, {
            count: sourceRows.length,
            context: move ? 'moved' : undefined,
          });
        }
      }
    }
  }
);

export type AddServicePackageParam = {
  tab: QuoteProductTabs;
  phaseId: ServicesPhases;
  phaseDesc: string;
  atIndex?: number;
  gridId?: string;
};

export const addServicePackageAction = createAction<AddServicePackageParam>(
  'quote/productsTab/addServicePackageAction'
);

export type DeleteServicesPhaseParam = {
  tab: QuoteProductTabs;
  gridId: string;
};

export const deleteServicesPhaseInternalAction = createAction<DeleteServicesPhaseParam>(
  'quote/productsTab/deleteServicesPhaseInternalAction'
);

export const deleteServicesPhaseAction = createAsyncThunk(
  'quote/productsTab/deleteServicesPhaseAction',
  async (payload: DeleteServicesPhaseParam, thunkAPI) => {
    // Remove validation errors for all service rows in this grid.
    const grids = getProductSelectors(payload.tab).servicesGrids(thunkAPI.getState() as RootState);
    const rows = grids?.find((grid) => grid.gridId === payload.gridId)?.rows;
    rows?.forEach((row) => {
      thunkAPI.dispatch(
        reviewTabSlice.actions.clearAllValidationErrorsForProduct({
          rowId: row.rowId,
          groupName: getGridValidationGroupNameByLocation(payload.tab, QuoteInnerTabs.Services),
        })
      );
    });

    thunkAPI.dispatch(deleteServicesPhaseInternalAction(payload));
  }
);

interface MovePhaseParam {
  sourceGrid: IServicesGrid;
  toOppositeProductsTab: boolean;
  toIndex?: number;
  showSuccessMessage?: boolean;
}

export const movePhaseAction = createAsyncThunk(
  'quote/productsTab/movePhaseAction',
  async (param: MovePhaseParam, thunkAPI) => {
    const { sourceGrid, toOppositeProductsTab, toIndex, showSuccessMessage } = param;
    const sourceTab = sourceGrid.tab;
    const targetTab = toOppositeProductsTab ? getOppositeProductsTab(sourceTab) : sourceTab;

    // Copy phase to target tab. Pass in id so that we can find the new grid.
    const gridId = v4();
    thunkAPI.dispatch(
      addServicePackageAction({
        tab: targetTab,
        phaseId: sourceGrid.phaseId,
        phaseDesc: sourceGrid.phaseDesc,
        atIndex: toIndex,
        gridId,
      })
    );

    // Find the newly added grid so that we can copy rows to it.
    const targetGrid = getProductSelectors(targetTab)
      .servicesGrids(thunkAPI.getState() as RootState)
      .find((grid) => grid.gridId === gridId);
    if (targetGrid) {
      // Copy items.
      const sourceRows = sourceGrid.rows;
      if (sourceRows.length > 0) {
        thunkAPI.dispatch(
          addServicesAction({
            tab: targetGrid.tab,
            gridId: targetGrid.gridId,
            phaseId: targetGrid.phaseId,
            services: sourceRows,
            source: 'copy',
          })
        );
        thunkAPI.dispatch(updateServicesDriverAndTruckTotalsAction({ tab: targetGrid.tab }));
      }

      // Delete original grid.
      thunkAPI.dispatch(
        deleteServicesPhaseAction({
          tab: sourceTab,
          gridId: sourceGrid.gridId,
        })
      );
      thunkAPI.dispatch(updateServicesDriverAndTruckTotalsAction({ tab: sourceTab }));

      if (showSuccessMessage !== false) {
        showNotification(thunkAPI.dispatch, 'success', i18nKeys.quote.services.packageMoved, {
          count: sourceRows.length,
        });
      }
    }
  }
);

export interface ShiftServicePackageParams extends DeleteServicesPhaseParam {
  direction: 'up' | 'down';
}

export const shiftServicePackage = createAsyncThunk(
  'quote/productsTab/shiftServicePackageAction',
  async (param: ShiftServicePackageParams, thunkAPI) => {
    const { tab, gridId, direction } = param;
    const grids = getProductSelectors(tab).servicesGrids(thunkAPI.getState() as RootState);
    const gridIndex = grids.findIndex((grid) => grid.gridId === gridId);
    if (gridIndex >= 0) {
      const toIndex = findTargetIndexForRowShift(grids, gridIndex, direction);
      if (toIndex !== null) {
        thunkAPI.dispatch(
          movePhaseAction({
            sourceGrid: grids[gridIndex],
            toOppositeProductsTab: false,
            toIndex,
            showSuccessMessage: false,
          })
        );
      }
    }
  }
);

export const serviceRateAdjustment = createAsyncThunk(
  'quote/productsTab/serviceRateAdjustment',
  async (payload: { percentage?: number; reset?: boolean; gridId: string }, thunkAPI) => {
    const { gridId } = payload;
    const state = thunkAPI.getState() as RootState;
    const tab = state.quoteScreen.tabs.active as QuoteProductTabs;
    if (payload.reset) {
      thunkAPI.dispatch(resetServiceRatesAction({ tab, gridId }));
      return;
    }
    if (isNil(payload.percentage)) {
      return;
    }
    const grid = findServicesGrid(state.quoteScreen.productsTab, tab, gridId);
    if (!grid) {
      return;
    }
    grid.selected.forEach((rowId: string) => {
      thunkAPI.dispatch(
        updateServicePercentageOfRateAction({
          location: { gridId, rowId, tab },
          value: payload.percentage!,
        })
      );
    });
  }
);

export interface UpdateServicePercentageOfRateParam {
  location: ServiceRowLocation;
  value: number;
}

export const updateServicePercentageOfRateAction = createAction<UpdateServicePercentageOfRateParam>(
  'quote/productsTab/updateServicePercentageOfRate'
);

export interface ResetServiceRatesParam {
  tab: QuoteProductTabs;
  gridId: string;
}

export const resetServiceRatesAction = createAction<ResetServiceRatesParam>('quote/productsTab/resetServiceRates');
