import { PayloadAction } from '@reduxjs/toolkit';
import { CrudActions, QuoteProductTabs, QuoteTabs } from 'enums';
import { IServicePhase, IServicesGrid, IServicesGridRow } from 'interfaces';
import { isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { round2Decimals } from '../../../utils';
import { getProductsStateByTab, makeInitialGridSelectionState } from '../../utils';
import { ShiftProductRowPayload } from './products-tab.action-creators';
import { ProductsTabState } from './products-tab.types';
import {
  AddServicePackageParam,
  DeleteServicesPhaseParam,
  ResetServiceRatesParam,
  SetCopyingServicesPayload,
  UpdateServicePercentageOfRateParam,
} from './services.actions';
import { findServiceRow, findServicesGrid, recalculateServiceRow, touchServicesGrid } from './services.helpers';

export const fetchServicesPhasesPending = (state: ProductsTabState, action: any) => {
  const tab = action.meta.arg.tab;
  const productsState = getProductsStateByTab(tab, state);
  if (productsState) {
    productsState.services.phases.fetching = true;
    productsState.services.phases.fetched = false;
  }
};

export const fetchServicesPhasesRejected = (state: ProductsTabState, action: any) => {
  const tab = action.meta.arg.tab;
  const productsState = getProductsStateByTab(tab, state);
  if (productsState) {
    productsState.services.phases.fetching = false;
  }
};

// Todo: Service phases should be fetched only once and should be stored outside of products state.
export const fetchServicesPhasesFulfilled = (
  state: ProductsTabState,
  { payload }: { payload: { tab: QuoteTabs; phases: IServicePhase[] } }
) => {
  const tab = payload.tab;
  const productsState = getProductsStateByTab(tab, state);
  if (productsState) {
    productsState.services.phases.fetching = false;
    productsState.services.phases.fetched = true;
    productsState.services.phases.data = payload.phases || [];
  }
};

const addServicePackageImpl = (state: ProductsTabState, param: AddServicePackageParam) => {
  const { tab, phaseId, phaseDesc, gridId = uuidv4() } = param;
  let { atIndex } = param;
  const { services } = state[tab];
  services.status.touched = true;
  const phase: IServicesGrid = {
    status: {
      existingItem: false,
      action: CrudActions.CREATE,
    },
    tab,
    gridId,
    phaseId,
    phaseDesc,
    ...makeInitialGridSelectionState(),
    rows: [],
    driverAndTruckTotals: {
      driverCount: 0,
      truckCount: 0,
    },
  };
  const { grids } = services;
  if (isNil(atIndex) || Number.isNaN(atIndex) || atIndex < 0 || atIndex > grids.length) {
    atIndex = grids.length;
  }
  grids.splice(atIndex, 0, phase);
};

export const addServicePackage = (state: ProductsTabState, { payload }: PayloadAction<AddServicePackageParam>) => {
  addServicePackageImpl(state, payload);
};

export const addOneServicePackageForEachPhase = (
  state: ProductsTabState,
  { payload: tab }: PayloadAction<QuoteProductTabs>
) => {
  for (let phase of state[tab].services.phases.data) {
    addServicePackageImpl(state, { tab, phaseId: phase.id, phaseDesc: '' });
  }
};

export const updateServicesPhaseDesc = (
  state: ProductsTabState,
  { payload }: { payload: { tab: QuoteProductTabs; gridId: string; phaseDesc: string } }
) => {
  const { tab, gridId, phaseDesc } = payload;
  const grid = findServicesGrid(state, tab, gridId);
  if (grid) {
    grid.phaseDesc = phaseDesc;
    touchServicesGrid(state, grid);
  }
};

export const deleteServicesPhase = (state: ProductsTabState, action: PayloadAction<DeleteServicesPhaseParam>) => {
  const { tab, gridId } = action.payload;
  const productsState = getProductsStateByTab(tab, state);
  if (productsState) {
    const grid = findServicesGrid(state, tab, gridId);
    if (grid) {
      productsState.services.status.touched = true;
      // If grid comes from the backend then change its status and hide it
      if (grid.status.action !== CrudActions.CREATE) {
        grid.status.action = CrudActions.DELETE;
      } else {
        // If grid has not been saved yet, just remove it
        productsState.services.grids = productsState.services.grids.filter((grid) => grid.gridId !== gridId);
      }
    }
  }
};

export const setCopyingServices = (state: ProductsTabState, action: PayloadAction<SetCopyingServicesPayload>) => {
  const { tab, copying } = action.payload;
  state[tab].services.copyingServices = copying;
};

interface ShiftServiceRowPayload extends Omit<ShiftProductRowPayload, 'innerTab'> {
  gridId: string;
}

export const shiftServiceRow = (state: ProductsTabState, { payload }: PayloadAction<ShiftServiceRowPayload>) => {
  const { tab, gridId, rowIndex, direction } = payload;
  const grid = findServicesGrid(state, tab, gridId);
  if (grid) {
    const move = (toIndex: number) => {
      grid.rows.splice(toIndex, 0, grid.rows.splice(rowIndex, 1)[0]);
      touchServicesGrid(state, grid);
    };
    if (direction === 'up') {
      if (rowIndex > 0) {
        move(rowIndex - 1);
      }
    } else if (rowIndex < grid.rows.length - 1) {
      move(rowIndex + 1);
    }
  }
};

interface MoveServiceRowsParam {
  tab: QuoteProductTabs;
  gridId: string;
  rowIds: string[];
  beforeRowId: string | null;
}

export const moveServiceRows = (state: ProductsTabState, { payload }: PayloadAction<MoveServiceRowsParam>) => {
  const { tab, gridId, rowIds, beforeRowId } = payload;
  const grid = findServicesGrid(state, tab, gridId);
  if (grid) {
    let toIndex = -1;
    const rowIdSet = new Set(rowIds);
    const rowsToMove: IServicesGridRow[] = [];
    const rows: IServicesGridRow[] = [];
    for (const row of grid.rows) {
      if (row.rowId === beforeRowId) {
        toIndex = rows.length;
      }
      if (rowIdSet.has(row.rowId)) {
        rowsToMove.push(row);
      } else {
        rows.push(row);
      }
    }
    if (toIndex === -1) {
      toIndex = rows.length;
    }
    rows.splice(toIndex, 0, ...rowsToMove);
    grid.rows = rows;
    touchServicesGrid(state, grid);
  }
};

export const updateServicePercentageOfRate = (
  state: ProductsTabState,
  action: PayloadAction<UpdateServicePercentageOfRateParam>
) => {
  const { location, value } = action.payload;
  const { grid, row } = findServiceRow(state, location);
  if (grid && row && !row.ratedManually) {
    const { regularRate, listRate } = row;
    if (!isNil(regularRate) && !isNil(listRate)) {
      const newRate = round2Decimals((listRate * value) / 100);
      if (newRate !== regularRate) {
        const mods = row.tier?.modifications;
        // noinspection PointlessBooleanExpressionJS
        if (
          !(
            mods &&
            ((mods.increase === false && newRate > listRate) ||
              (mods.decrease === false && newRate < listRate) ||
              (mods.maximum && newRate > mods.maximum) ||
              (mods.minimum && newRate < mods.minimum))
          )
        ) {
          row.regularRate = newRate;
          recalculateServiceRow(state, grid, row, true);
        }
      }
    }
  }
};

/**
 * Reset rates for all selected rows in the specified services grid.
 */
export const resetServiceRates = (
  state: ProductsTabState,
  { payload: { tab, gridId } }: PayloadAction<ResetServiceRatesParam>
) => {
  const grid = findServicesGrid(state, tab, gridId);
  grid?.selected.forEach((rowId: string) => {
    const row = grid!.rows.find((row) => row.rowId === rowId);
    if (row && !row.ratedManually && !isNil(row.listRate)) {
      row.regularRate = row.listRate;
      recalculateServiceRow(state, grid, row, true);
    }
  });
};
