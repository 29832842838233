export interface IRateTier {
  color: IRateColor;
  id: string;
  modifications: {
    increase?: boolean;
    decrease?: boolean;
    minimum?: number;
    maximum?: number;
  };
}

enum RedlineLevels {
  GOOD = 'GOOD',
  WARNING = 'WARNING',
  BAD = 'BAD',
}

export interface IRateLevel {
  id: RedlineLevels;
  minimum: number;
  maximum: number;
  color: IRateColor;
}

export interface IRateColor {
  name: string;
  code: {
    rgba: number[];
    hex: string;
  };
}
