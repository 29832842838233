import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ListItemIcon, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../internationalization/i18nKeys';

export const MenuItemLabel: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Typography variant="inherit">{children}</Typography>
);

export const PreviewMenuItemContent: React.FC = () => (
  <>
    <ListItemIcon>
      <PictureAsPdfIcon fontSize="small" />
    </ListItemIcon>
    <MenuItemLabel>Preview</MenuItemLabel>
  </>
);

export const CloneMenuItemContent: React.FC = () => (
  <>
    <ListItemIcon>
      <FileCopyIcon fontSize="small" />
    </ListItemIcon>
    <MenuItemLabel>Clone ...</MenuItemLabel>
  </>
);

export const MarkWonMenuItemContent: React.FC = () => (
  <>
    <ListItemIcon>
      <CheckIcon fontSize="small" />
    </ListItemIcon>
    <MenuItemLabel>Mark As Won ...</MenuItemLabel>
  </>
);

export const MarkLostMenuItemContent: React.FC = () => (
  <>
    <ListItemIcon>
      <CloseIcon fontSize="small" />
    </ListItemIcon>
    <MenuItemLabel>Mark As Lost ...</MenuItemLabel>
  </>
);

export const DeleteMenuItemContent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <ListItemIcon>
        <DeleteIcon fontSize="small" color="error" />
      </ListItemIcon>
      <MenuItemLabel>{t(i18nKeys.common.deleteWithConfirmationLabel)}</MenuItemLabel>
    </>
  );
};
