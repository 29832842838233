import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { QuoteService } from 'api';
import {
  convertCustomerTabToSaveRequest,
  convertDetailsTabToSaveRequest,
  convertFeesToSaveRequest,
  convertNotesToSaveRequest,
  convertRentalTabToSaveRequest,
  convertSalesTabToSaveRequest,
  convertServicesTabToSaveRequest,
} from 'converters';
import { QuoteGroups, QuoteTabs, Statuses } from 'enums';
import i18next from 'i18next';
import { IFetchQuoteResult, IOutboundGroup, IQuote, ISaveQuoteResult, IValidationError } from 'interfaces';
import {
  CustomerTabSelectors,
  enqueueNotificationAction,
  QuoteScreenSelectors,
  QuoteSelectors,
  updateQuoteFromResponse,
} from 'rdx';
import { SessionState } from 'rdx/session';
import { UpdateRelatedUserRequestData } from '../../api/quote.service.types';
import { i18nKeys } from '../../internationalization/i18nKeys';
import { setClientSideValidationErrors } from '../quote-screen/review-tab/actions';
import { ClientSideValidationCodes } from '../quote-screen/review-tab/validation/validation';
import { saveStatementOfWorkAction } from '../quote-screen/statement-tab/saveStatementOfWorkAction';
import { RootState } from '../types';
import { updateActiveBranch } from './user.reducers';

export const fetchQuoteForCloneAction = createAsyncThunk(
  'session/fetchQuoteForCloneAction',
  async (payload: { quoteId: string; groups: QuoteGroups[] }, thunkAPI): Promise<IFetchQuoteResult> => {
    const cloneOptions = (thunkAPI.getState() as RootState).session.quote.clone?.options;
    const { quoteId, groups } = payload;
    const response = await QuoteService.getInstance().getQuoteGroups(quoteId, groups);
    return {
      quote: response,
      groups,
      reset: false,
      cloneOptions,
    };
  }
);

export const fetchQuotePending = (state: SessionState) => {
  state.quote.new = false;
  state.quote.fetching = true;
};

export const fetchQuoteRejected = (state: SessionState) => {
  state.quote.fetching = false;
  state.quote.fetched = false;
};

export const fetchQuoteFulfilled = (state: SessionState, { payload }: PayloadAction<IFetchQuoteResult>) => {
  const { quote } = payload;

  state.quote.fetching = false;
  state.quote.fetched = true;

  // Assumes it was saved before if you are fetching it
  state.quote.saved = true;

  state.quote.data = updateQuoteFromResponse(state.quote.data, quote);

  // Sets the default branch to the one from the Quote fetched
  updateActiveBranch(state, quote.header.main.branch.id || '');
};

export const saveQuoteAction = createAsyncThunk<ISaveQuoteResult, undefined>(
  'session/saveQuoteAction',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const quoteId = state.session.quote.data?.id || null;
    let response: IQuote = {} as IQuote;
    const includedGroupTypes: QuoteGroups[] = [];
    const groups: IOutboundGroup[] = [];

    const errors: IValidationError[] = [];

    const addClientSideValidationError = (error: boolean, code: ClientSideValidationCodes) => {
      if (error) {
        errors.push({ code, message: i18next.t(i18nKeys.validation.clientSideErrors[code]) });
      }
    };

    // Prevent saving of new quotes with inactive account.
    if (!quoteId) {
      addClientSideValidationError(
        CustomerTabSelectors.account(state)?.statusId === Statuses.INACTIVE,
        ClientSideValidationCodes.AccountClosed
      );
    }

    addClientSideValidationError(
      !CustomerTabSelectors.jobsiteJobName(state)?.trim(),
      ClientSideValidationCodes.MissingJobsiteName
    );

    addClientSideValidationError(
      !CustomerTabSelectors.marketSegmentId(state),
      ClientSideValidationCodes.MissingMarketSegment
    );

    addClientSideValidationError(
      typeof CustomerTabSelectors.billingTaxExempt(state) !== 'boolean',
      ClientSideValidationCodes.MissingTaxExempt
    );

    addClientSideValidationError(
      typeof CustomerTabSelectors.billingWorkOfImprovement(state) !== 'boolean',
      ClientSideValidationCodes.MissingWorkOfImprovement
    );

    addClientSideValidationError(
      typeof CustomerTabSelectors.billingPrevailingWage(state) !== 'boolean',
      ClientSideValidationCodes.MissingPrevailingWage
    );

    if (errors.length > 0) {
      thunkAPI.dispatch(setClientSideValidationErrors(errors));
      thunkAPI.dispatch(
        enqueueNotificationAction({
          message: i18next.t(i18nKeys.validation.fixErrorsPreventingSave),
          options: { variant: 'error' },
        })
      );
      return thunkAPI.rejectWithValue('Validation failed');
    }

    try {
      const showSuccessMessage = () => {
        thunkAPI.dispatch(enqueueNotificationAction({ message: 'Quote saved', options: { variant: 'success' } }));
      };

      if (!quoteId) {
        const clone = QuoteSelectors.clone(state);
        if (clone) {
          const quote = convertCustomerTabToSaveRequest(state, true).content as any;
          response = await QuoteService.getInstance().cloneQuote(clone.cloneFromId, clone.options, quote);
        } else {
          const quote = convertCustomerTabToSaveRequest(state, false).content as any;
          response = await QuoteService.getInstance().createQuote(quote);
        }
        includedGroupTypes.push(QuoteGroups.ACCOUNT);
        showSuccessMessage();
      } else {
        const addGroup = (group: IOutboundGroup | null, groupId: QuoteGroups) => {
          if (group) {
            groups.push(group);
            includedGroupTypes.push(groupId);
          }
        };

        if (QuoteScreenSelectors.customerTabTouched(state)) {
          addGroup(convertCustomerTabToSaveRequest(state, false), QuoteGroups.ACCOUNT);
        }
        if (QuoteScreenSelectors.detailsTabTouched(state)) {
          addGroup(convertDetailsTabToSaveRequest(state), QuoteGroups.DETAILS);
        }

        if (QuoteScreenSelectors.rentalTouched(state)) {
          const groupId = QuoteGroups.RENTAL;
          addGroup(convertRentalTabToSaveRequest(state, QuoteTabs.Products, groupId), groupId);
        }
        if (QuoteScreenSelectors.optionalRentalTouched(state)) {
          const groupId = QuoteGroups.OPTIONAL_RENTAL;
          addGroup(convertRentalTabToSaveRequest(state, QuoteTabs.Optional, groupId), groupId);
        }

        if (QuoteScreenSelectors.salesTouched(state)) {
          const groupId = QuoteGroups.SALES;
          addGroup(convertSalesTabToSaveRequest(state, QuoteTabs.Products, groupId), groupId);
        }
        if (QuoteScreenSelectors.optionalSalesTouched(state)) {
          const groupId = QuoteGroups.OPTIONAL_SALES;
          addGroup(convertSalesTabToSaveRequest(state, QuoteTabs.Optional, groupId), groupId);
        }

        if (QuoteScreenSelectors.servicesTouched(state)) {
          const groupId = QuoteGroups.SERVICES;
          addGroup(convertServicesTabToSaveRequest(state, QuoteTabs.Products, groupId), groupId);
        }
        if (QuoteScreenSelectors.optionalServicesTouched(state)) {
          const groupId = QuoteGroups.OPTIONAL_SERVICES;
          addGroup(convertServicesTabToSaveRequest(state, QuoteTabs.Optional, groupId), groupId);
        }

        if (QuoteScreenSelectors.feesTouched(state)) {
          addGroup(convertFeesToSaveRequest(state), QuoteGroups.FEES);
        }
        if (QuoteScreenSelectors.optionalNotesTouched(state)) {
          addGroup(convertNotesToSaveRequest(state), QuoteGroups.OPTIONAL_NOTES);
        }

        let statementSaveFailed: boolean;
        [response, statementSaveFailed] = await Promise.all([
          QuoteService.getInstance().saveQuoteGroups(quoteId, { groups }),
          thunkAPI
            .dispatch(saveStatementOfWorkAction())
            .unwrap()
            .then(
              () => Promise.resolve(false),
              () => Promise.resolve(true)
            ),
        ]);

        if (!statementSaveFailed) {
          showSuccessMessage();
        }
      }

      return {
        saved: true,
        quote: response,
        groups: includedGroupTypes,
      } as ISaveQuoteResult;
    } catch (e) {
      thunkAPI.dispatch(
        enqueueNotificationAction({
          message: "Couldn't save the quote. Try again",
          options: { variant: 'error' },
        })
      );
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const saveQuotePendingAction = (state: SessionState) => {
  state.quote.saving = true;
};

export const saveQuoteRejectAction = (state: SessionState) => {
  state.quote.saving = false;
};

export const saveQuoteFulfillAction = (state: SessionState, action: any) => {
  const payload: ISaveQuoteResult = action.payload;
  state.quote.saving = false;
  state.quote.saved = true;
  state.quote.data = updateQuoteFromResponse(state.quote.data, payload.quote as IQuote);
};

export const fetchChangeOrdersAction = createAsyncThunk(
  'quote/fetchChangeOrdersAction',
  async (quoteId: string, thunkAPI) => {
    try {
      return await QuoteService.getInstance().listChangeOrders(quoteId);
    } catch (e) {
      thunkAPI.dispatch(
        enqueueNotificationAction({
          message: i18next.t(i18nKeys.changeOrderTab.errorGettingChangeOrdersMessage),
          options: {
            variant: 'error',
          },
        })
      );
      throw e;
    }
  }
);

export const updateRelatedUserAction = createAsyncThunk(
  'quote/updateRelatedUser',
  async ({ quoteId, data }: { quoteId: string; data: UpdateRelatedUserRequestData }, thunkAPI) => {
    return await QuoteService.getInstance().updateRelatedUser(quoteId, data);
  }
);
