import { QuoteGroups } from 'enums';
import { IOutboundDetailsGroup, IQuote } from 'interfaces';
import { DetailsTabSelectors } from 'rdx';
import { v4 } from 'uuid';
import { DetailsTabState } from '../rdx/quote-screen/details-tab/details-tab.types';
import { RootState } from '../rdx/types';

/*
 * Retrieve
 */
export const convertFetchResponseToDetailsTab = (quote: IQuote): DetailsTabState['details'] => {
  return {
    project: {
      name: quote.header.project?.name || '',
      what: quote.header.project?.what || '',
      description: quote.header.project?.description || '',
      duration: quote.header.project?.duration || '',
    },
    application: {
      id: quote.application?.templateId || '',
      templateId: quote.application?.templateId || '',
      fields: quote.application?.fields || [],
    },
    notes: {
      content: quote.header.notes?.content || '',
    },
    engineering: {
      jobNumber: quote.header.engineering?.jobNumber || '',
      comments: quote.header.engineering?.comments || '',
    },
    schedule: {
      deliveryTimestamp: quote.header.schedule?.deliveryTimestamp ?? undefined,
      completionTimestamp: quote.header.schedule?.completionTimestamp ?? undefined,
    },
  };
};

/*
 * Save
 */
export const convertDetailsTabToSaveRequest = (state: RootState): IOutboundDetailsGroup => {
  return {
    id: QuoteGroups.DETAILS,
    content: {
      header: {
        project: {
          name: DetailsTabSelectors.projectName(state),
          what: DetailsTabSelectors.projectWhat(state),
          duration: DetailsTabSelectors.projectDuration(state),
          description: DetailsTabSelectors.projectDescription(state),
        },
        notes: { content: DetailsTabSelectors.notesContent(state) },
        schedule: {
          deliveryTimestamp: DetailsTabSelectors.scheduleDelivery(state) ?? null,
          completionTimestamp: DetailsTabSelectors.scheduleCompletion(state) ?? null,
        },
        engineering: {
          jobNumber: DetailsTabSelectors.engineeringJobNumber(state),
          comments: DetailsTabSelectors.engineeringComments(state),
        },
      },
      application: {
        id: v4(),
        templateId: DetailsTabSelectors.applicationId(state),
        fields: DetailsTabSelectors.applicationFields(state),
      },
    },
  };
};
