export const quotePath = '/quote/:id';
export const changeOrderPath = '/quote/:id/change-order/:changeOrderId';

export enum RoutePaths {
  Customer = 'customer',
  Details = 'details',
  ScopeChanges = 'scope-changes',
  ProductsRental = 'products/rental',
  ProductsSales = 'products/sales',
  ProductsServices = 'products/services',
  Review = 'review',
  Approval = 'approval',
}

export const cognitoCallbackPath = '/auth/callback';
