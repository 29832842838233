import { CrudActions, QuoteGroups, QuoteProductTabs, QuoteTabs } from 'enums';
import {
  IOutboundGroup,
  IServicesDetail,
  IServicesDetailEvent,
  IServicesDetailEventBody,
  IServicesGrid,
  IServicesGridRow,
} from 'interfaces';
import { getProductSelectors, initLoadedServiceRow, makeInitialGridSelectionState } from 'rdx';
import { ChangeOrderGroups } from '../rdx/change-order/types';
import { RootState } from '../rdx/types';
import { checkPositiveNumber } from '../utils';
import { convertRowsToSaveEvents, createProductsGroup, makeDetailEventMetadata } from './common';

/*
 * Save
 */
const convertToServicesDetailEvent = (row: IServicesGridRow, idx: number): IServicesDetail => ({
  id: row.rowId,
  rowIndex: idx + 1,
  description: row.description,
  comments: row.comments ?? '',
  quantity: row.quantity,
  regularHours: row.regularHours,
  regularRate: row.regularRate,
  overtimeHours: row.overtimeHours,
  overtimeRate: row.overtimeRate,
  doubleTimeHours: row.doubleTimeHours,
  doubleTimeRate: row.doubleTimeRate,
  listRate: row.listRate,
  minimumRate: row.minimumRate,
  oneRulerRate: row.oneRulerRate,
  service: row.service,
  ratedManually: row.ratedManually,
  extension: row.extension || 0,
  rateTierId: row.rateTierId,
  rateTierColor: row.rateTierColor,
  redlineLevelId: row.redlineLevelId,
  redlineLevelColor: row.redlineLevelColor,
});

const convertServicesGridToDetailEvent = (
  state: null,
  grid: IServicesGrid,
  timestamp: number,
  action: CrudActions,
  gridNumber: number
) => {
  const event: IServicesDetailEvent = {
    metadata: makeDetailEventMetadata(grid.gridId, timestamp, action, gridNumber),
  };
  if (action !== CrudActions.DELETE) {
    event.data = {
      id: grid.gridId,
      rowIndex: gridNumber,
      servicePhaseId: grid.phaseId,
      description: grid.phaseDesc,
      serviceLines: grid.rows.map(convertToServicesDetailEvent),
    };
  }
  return event;
};

export const convertServicesTabToSaveRequest = (
  state: RootState,
  tab: QuoteTabs,
  groupId: QuoteGroups | ChangeOrderGroups
): IOutboundGroup | null => {
  const grids = getProductSelectors(tab).servicesGrids(state);
  const events = convertRowsToSaveEvents(null, grids, () => true, convertServicesGridToDetailEvent);
  return createProductsGroup(events, groupId);
};

/*
 * Fetch
 */
export const convertToServicesGrid = (
  event: IServicesDetailEventBody,
  tab: QuoteProductTabs,
  existingGrid?: IServicesGrid
): IServicesGrid => {
  const gridId = event.id;
  const selectionState = makeInitialGridSelectionState();

  // If this grid already exists, preserve selection. If user is resetting
  // the quote, selection may contain some rows that do not exist any more.
  // That's ok because grid will dispatch a selection change event, and those
  // rows will be removed.
  if (existingGrid) {
    selectionState.selected = existingGrid.selected;
    selectionState.selectedById = existingGrid.selectedById;
  }
  return {
    tab,
    gridId,
    phaseId: event.servicePhaseId,
    phaseDesc: event.description || '',
    ...selectionState,
    rows: event.serviceLines.map((line) => convertToServicesGridRow(line, tab, gridId)),
    status: {
      existingItem: true,
      action: CrudActions.READ,
    },
    driverAndTruckTotals: {
      driverCount: 0,
      truckCount: 0,
    },
  };
};

export const convertToServicesGridRow = (event: IServicesDetail, tab: QuoteProductTabs, gridId: string) => {
  const row: IServicesGridRow = {
    tab,
    gridId,
    rowId: event.id,
    profileFetched: false,
    serviceId: event.service?.serviceId,
    code: event.service?.code,
    description: event.description || event.service?.description,
    categoryId: event.service?.categoryId,
    comments: event.comments ?? '',
    unitId: event.service?.unitId,
    quantity: event.quantity,
    regularHours: event.regularHours,
    regularRate: event.regularRate,
    overtimeHours: event.overtimeHours,
    overtimeRate: event.overtimeRate,
    doubleTimeHours: event.doubleTimeHours,
    doubleTimeRate: event.doubleTimeRate,
    listRate: event.listRate,
    minimumRate: checkPositiveNumber(event.minimumRate, undefined),
    oneRulerRate: event.oneRulerRate || undefined,
    service: event.service,
    validations: {
      missingDriver: false,
      missingTruck: false,
    },
    status: {
      touched: true,
      error: false,
      fetching: false,
      fetched: true,
    },
    rateTierId: event.rateTierId,
    rateTierColor: event.rateTierColor,
    redlineLevelId: event.redlineLevelId,
    redlineLevelColor: event.redlineLevelColor,
    ratedManually: event.ratedManually,
    extension: event.extension,
    validationMessages: [],
    editorParams: {
      numeric: true,
      currency: true,
    },
  };
  initLoadedServiceRow(row);
  return row;
};
