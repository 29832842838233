import {
  CrudActions,
  GridFields,
  QuoteProductTabs,
  ServiceEventLevels,
  ServiceEvents,
  ServicesCategories,
} from 'enums';
import i18n from 'i18next';
import { IDriverAndTruckTotals, IServicesGrid, IServicesGridRow, ServiceRowLocation, WorkflowStates } from 'interfaces';
import { isEqual, isNil, sum } from 'lodash';
import { DOUBLE_TIME_MULTIPLIER, OVERTIME_MULTIPLIER, round2Decimals, checkPositiveNumber } from 'utils';
import { i18nKeys } from '../../../internationalization/i18nKeys';
import {
  updateDescription,
  updateRateTier,
  updateRedlineLevelColor,
  validateDescription,
} from './products-tab.helpers';
import { FetchServiceProfileResult, ProductsTabState, ServiceRowUpdate } from './products-tab.types';

export const findServicesGrid = (state: ProductsTabState, tab: QuoteProductTabs, gridId: string) =>
  state[tab].services.grids.find((grid) => grid.gridId === gridId);

export const findServiceRow = (state: ProductsTabState, { tab, gridId, rowId }: ServiceRowLocation) => {
  const grid = findServicesGrid(state, tab, gridId);
  return { grid, row: grid?.rows.find((item) => item.rowId === rowId) };
};

export function updateServiceRowProp<T extends keyof IServicesGridRow>(
  state: ProductsTabState,
  update: ServiceRowUpdate<T>,
  recalculate: boolean
) {
  const { row, grid } = findServiceRow(state, update.location);
  if (row && grid) {
    row[update.prop] = update.value;
    if (recalculate) {
      recalculateServiceRow(state, grid, row, true);
    } else {
      touchServicesGrid(state, grid);
    }
  }
}

export const initLoadedServiceRow = (row: IServicesGridRow) => {
  if (row.extension === 0) {
    row.extension = undefined;
  }
  if (row.regularHours === 0) {
    row.regularHours = undefined;
  }
  if (row.overtimeHours === 0) {
    row.overtimeHours = undefined;
  }
  if (row.doubleTimeHours === 0) {
    row.doubleTimeHours = undefined;
  }
};

/**
 * Update all derived properties when something about the row changes.
 */
export const recalculateServiceRow = (
  state: ProductsTabState,
  grid: IServicesGrid,
  row: IServicesGridRow,
  touched: boolean
) => {
  if (row.profileFetched && state.quoteStateId === WorkflowStates.OPEN) {
    const { ratedManually, regularRate, colors, driverAndTruck, quantity, tab } = row;

    let overtimeRate = 0;
    let doubleTimeRate = 0;
    if (!isNil(regularRate)) {
      overtimeRate = regularRate * OVERTIME_MULTIPLIER;
      doubleTimeRate = regularRate * DOUBLE_TIME_MULTIPLIER;
    }
    if (overtimeRate !== row.overtimeRate || doubleTimeRate !== row.doubleTimeRate) {
      row.overtimeRate = overtimeRate;
      row.doubleTimeRate = doubleTimeRate;
      touched = true;
    }

    const extension = calcServiceRowExtension(row);
    if (extension !== row.extension) {
      row.extension = extension;
      touched = true;
    }

    let redlineLevelId;
    if (!ratedManually && !isNil(regularRate) && colors) {
      redlineLevelId = colors.find((level) => regularRate >= level.minimum && regularRate <= level.maximum)?.id;
    }
    if (redlineLevelId !== row.redlineLevelId) {
      row.redlineLevelId = redlineLevelId;
      touched = true;
    }
    if (updateRedlineLevelColor(row)) {
      touched = true;
    }

    if (driverAndTruck) {
      const count = quantity || 0;
      if (count !== driverAndTruck.count) {
        driverAndTruck.count = count;
        updateServicesDriverAndTruckTotals(state, tab);
      }
    }

    validateServiceRow(row);

    if (touched) {
      touchServicesGrid(state, grid);
    }
  }
};

const calcServiceRowExtension = (row: IServicesGridRow) => {
  const { doubleTimeRate, regularHours, doubleTimeHours, overtimeRate, regularRate, overtimeHours, quantity } = row;
  if (row.onlyRate) {
    return regularRate;
  } else if (!quantity) {
    return undefined;
  } else {
    let regular, ot, dt;
    if (regularHours && regularRate) {
      regular = regularHours * regularRate;
    }
    if (overtimeHours && overtimeRate) {
      ot = overtimeHours * overtimeRate;
    }
    if (doubleTimeHours && doubleTimeRate) {
      dt = doubleTimeHours * doubleTimeRate;
    }
    if (regular || ot || dt) {
      return round2Decimals(quantity * sum([regular, ot, dt]));
    }
    return undefined;
  }
};

/**
 * Builds array of validation messages for the row to display in R.V. cell.
 */
const validateServiceRow = (row: IServicesGridRow) => {
  const keys = i18nKeys.services.validationMessages;
  const validationMessages: string[] = [];
  const { quantity, regularRate, regularHours, overtimeHours, doubleTimeHours, validations } = row;

  const addValidationMessage = (key: string, options?: object) => {
    validationMessages.push(i18n.t(key, options));
  };

  validateDescription(row, validationMessages);

  if (!quantity && row.fields?.includes(GridFields.QUANTITY)) {
    addValidationMessage(keys.missingQuantity);
  }
  if (!regularRate && row.fields?.includes(GridFields.REGULAR_RATE)) {
    addValidationMessage(keys.missingRate);
  }
  if (validations.missingDriver) {
    addValidationMessage(keys.missingDriver);
  }
  if (validations.missingTruck) {
    addValidationMessage(keys.missingTruck);
  }

  if (row.categoryId === ServicesCategories.LABOR) {
    if (
      !regularHours &&
      row.fields?.includes(GridFields.REGULAR_HOURS) &&
      !overtimeHours &&
      row.fields?.includes(GridFields.OVERTIME_HOURS) &&
      !doubleTimeHours &&
      row.fields?.includes(GridFields.DOUBLE_TIME_HOURS)
    ) {
      addValidationMessage(keys.missingHours);
    }
  } else {
    if (!regularHours && row.fields?.includes(GridFields.REGULAR_HOURS)) {
      addValidationMessage(keys.missingRegDuration);
    }
  }

  if (row.validationMessages.length !== 0 || validationMessages.length !== 0) {
    row.validationMessages = validationMessages;
  }
};

const updateServiceRowsDriverAndTruckValidity = (
  grid: IServicesGrid,
  eventLevel: ServiceEventLevels,
  totals: IDriverAndTruckTotals
) => {
  if (grid.status.action !== CrudActions.DELETE) {
    for (const row of grid.rows) {
      const { driverAndTruck, validations } = row;
      if (driverAndTruck && driverAndTruck.level === eventLevel) {
        const { isTruck, isDriver } = driverAndTruck;
        const missingDriver = isTruck && totals.truckCount > totals.driverCount;
        const missingTruck = isDriver && totals.driverCount > totals.truckCount;
        if (missingDriver !== validations.missingDriver || missingTruck !== validations.missingTruck) {
          validations.missingTruck = missingTruck;
          validations.missingDriver = missingDriver;
          validateServiceRow(row);
        }
      }
    }
  }
};

export const updateServicesDriverAndTruckTotals = (state: ProductsTabState, tab: QuoteProductTabs) => {
  const { services } = state[tab];
  const overallTotals: IDriverAndTruckTotals = { driverCount: 0, truckCount: 0 };
  for (const grid of services.grids) {
    if (grid.status.action !== CrudActions.DELETE) {
      const gridTotals: IDriverAndTruckTotals = { driverCount: 0, truckCount: 0 };
      for (const { driverAndTruck } of grid.rows) {
        if (driverAndTruck) {
          const totals = driverAndTruck.level === ServiceEventLevels.ALL ? overallTotals : gridTotals;
          const { isDriver, isTruck, count } = driverAndTruck;
          if (isDriver) {
            totals.driverCount += count;
          }
          if (isTruck) {
            totals.truckCount += count;
          }
        }
      }
      if (!isEqual(gridTotals, grid.driverAndTruckTotals)) {
        grid.driverAndTruckTotals = gridTotals;
        updateServiceRowsDriverAndTruckValidity(grid, ServiceEventLevels.PHASE, gridTotals);
      }
    }
  }
  if (!isEqual(overallTotals, services.driverAndTruckTotals)) {
    services.driverAndTruckTotals = overallTotals;
    for (const grid of services.grids) {
      updateServiceRowsDriverAndTruckValidity(grid, ServiceEventLevels.ALL, overallTotals);
    }
  }
};

export const updateServiceRowFromProfile = (
  state: ProductsTabState,
  grid: IServicesGrid,
  row: IServicesGridRow,
  payload: FetchServiceProfileResult
) => {
  const { profile } = payload;
  const service = profile.body.entity;
  const { automated, override, rate, tier, fields, redline, events } = profile.body.custom;

  row.status.fetching = false;
  row.status.fetched = true;
  row.status.error = false;
  row.profileFetched = true;

  row.code = service.code;
  row.colors = redline?.levels || [];

  if (state.quoteStateId === WorkflowStates.OPEN) {
    let updated = false;

    row.serviceId = service.serviceId;
    row.categoryId = service.categoryId;
    row.unitId = service.unitId;
    row.ratedManually = override || !automated;

    row.service = service;
    row.fields = fields?.editable || [];

    const descriptionEditable = row.fields.includes('description');
    if (updateDescription(row, service.description, descriptionEditable)) {
      updated = true;
    }

    if (automated) {
      const listRate = rate.rate;
      if (listRate !== row.listRate) {
        // New list rate must be saved for TQH
        row.listRate = listRate;
        updated = true;
      }
      if (payload.setRates) {
        row.regularRate = listRate;
      }
    }

    const oneRulerRate = checkPositiveNumber(rate?.oneRulerRate, undefined);
    if (row.oneRulerRate !== oneRulerRate) {
      row.oneRulerRate = oneRulerRate;
      updated = true;
    }

    const modifications = tier?.modifications;

    let minimumRate = checkPositiveNumber(modifications?.minimum, undefined);
    if (row.minimumRate !== minimumRate) {
      row.minimumRate = minimumRate;
      updated = true;
    }

    row.editorParams = {
      numeric: true,
      currency: true,
      increase: modifications?.increase,
      decrease: modifications?.decrease,
      minValue: modifications?.minimum,
      maxValue: modifications?.maximum,
      originValue: row.listRate,
    };

    row.onlyRate = !row.fields.includes(GridFields.QUANTITY) && row.fields.includes(GridFields.REGULAR_RATE);

    const driverTruckEvent = events.find((e) => e.id === ServiceEvents.DRIVER_TRUCK);
    if (driverTruckEvent) {
      const isDriver = driverTruckEvent.services.driver.indexOf(service.serviceId) > -1;
      const isTruck = driverTruckEvent.services.truck.indexOf(service.serviceId) > -1;
      if (isDriver || isTruck) {
        row.driverAndTruck = {
          isDriver,
          isTruck,
          level: driverTruckEvent.level,
          count: 0,
        };
      }
    }

    if (updateRateTier(row, tier)) {
      updated = true;
    }

    recalculateServiceRow(state, grid, row, updated);
  } else {
    updateRateTier(row, tier);
    updateRedlineLevelColor(row);
  }
};

export const touchServicesGrid = (state: ProductsTabState, grid: IServicesGrid) => {
  if (grid.status.existingItem) {
    grid.status.action = CrudActions.UPDATE;
  }
  state[grid.tab].services.status.touched = true;
};

export const getServiceKey = (servicePhaseId: string, code: string) => `${servicePhaseId}🌧️💰${code}`;
