import { RootState } from 'rdx/types';

const details = (state: RootState) => state.quoteScreen.detailsTab.details;

export const DetailsTabSelectors = {
  fetching: (state: RootState) => state.quoteScreen.detailsTab.status.fetching,
  fetched: (state: RootState) => state.quoteScreen.detailsTab.status.fetched,
  config: (state: RootState) => state.quoteScreen.detailsTab.config.data,
  configFetching: (state: RootState) => state.quoteScreen.detailsTab.config.status.fetching,
  configFetched: (state: RootState) => state.quoteScreen.detailsTab.config.status.fetched,
  details,

  project: (state: RootState) => details(state).project,
  notes: (state: RootState) => details(state).notes,
  schedule: (state: RootState) => details(state).schedule,
  engineering: (state: RootState) => details(state).engineering,
  application: (state: RootState) => details(state).application,

  projectName: (state: RootState) => DetailsTabSelectors.project(state).name,
  projectWhat: (state: RootState) => DetailsTabSelectors.project(state).what,
  projectDuration: (state: RootState) => DetailsTabSelectors.project(state).duration,
  projectDescription: (state: RootState) => DetailsTabSelectors.project(state).description,

  notesContent: (state: RootState) => DetailsTabSelectors.notes(state).content,

  scheduleDelivery: (state: RootState) => DetailsTabSelectors.schedule(state).deliveryTimestamp,
  scheduleCompletion: (state: RootState) => DetailsTabSelectors.schedule(state).completionTimestamp,

  engineeringJobNumber: (state: RootState) => DetailsTabSelectors.engineering(state).jobNumber,
  engineeringComments: (state: RootState) => DetailsTabSelectors.engineering(state).comments,

  applicationId: (state: RootState) => DetailsTabSelectors.application(state).id,
  applicationFields: (state: RootState) => DetailsTabSelectors.application(state).fields,
};
